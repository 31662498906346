import React, { Fragment } from 'react';
import { get, maxBy } from 'lodash';
import { getPriceString } from 'site-modules/shared/utils/price-utils';

export const getPricingLabel = (trim, isDisplayLabel) => {
  const totalMsrp = get(trim, 'pricing.totalMsrp');
  const msrpText = isDisplayLabel ? (
    <Fragment>
      {' '}
      - <strong>{getPriceString(totalMsrp)} MSRP</strong>
    </Fragment>
  ) : (
    ` - ${getPriceString(totalMsrp)} MSRP`
  );
  return totalMsrp ? msrpText : '';
};

export const getTrimsWithLabels = (trims, isCore6148Enabled) => {
  const mostPopularStyleId = get(maxBy(trims, 'shareBySalesSum'), 'styleId');

  return trims
    .map(trim => ({
      ...trim,
      label: `${trim.trimName}${trim.styleId === mostPopularStyleId ? ` (Most Popular)` : ''}${getPricingLabel(trim)}`,
      displayLabel: (
        <Fragment>
          {trim.trimName}
          {trim.styleId === mostPopularStyleId ? ` (Most Popular)` : ''}
          {getPricingLabel(trim, !isCore6148Enabled)}
        </Fragment>
      ),
    }))
    .sort((a, b) => get(a, 'pricing.baseMsrp', 0) - get(b, 'pricing.baseMsrp', 0));
};

export function getTrimValue(trim) {
  const name = trim.trimName || trim.name || trim.trim;
  return name
    .replace('(Most Popular)', '')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/\./g, '')
    .toLowerCase();
}
