import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, compact } from 'lodash';
import { connect } from 'react-redux';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VisitorModel } from 'client/data/models/visitor';
import { useOnScreen } from 'site-modules/shared/hooks/use-on-screen';
import { useTimeout } from 'site-modules/shared/hooks/use-timeout';

const DEFAULT_TIMEOUT = 2000;

export function SpeculationRuleUI({
  observeRef,
  observeTimeout,
  urls,
  paths,
  selector,
  notSelector,
  action,
  eagerness,
  disableSpeculation,
  zipCode,
}) {
  const [isOnScreen] = useOnScreen(observeRef);
  const [setViewTimeout, clearViewTimeout] = useTimeout();
  const [canSpeculate, setCanSpeculate] = useState(false);
  const id = `speculate-${React.useId().replace(/\W/g, '')}`;

  useEffect(() => {
    setCanSpeculate(false);
  }, [zipCode]);

  useEffect(() => {
    if (!observeRef?.current || !isOnScreen || canSpeculate) {
      clearViewTimeout();
      return;
    }

    setViewTimeout(() => {
      setCanSpeculate(true);
    }, observeTimeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnScreen]);

  useEffect(() => {
    if (
      !canSpeculate ||
      disableSpeculation ||
      (!paths?.[0] && !urls?.[0] && !selector) ||
      !HTMLScriptElement.supports ||
      !HTMLScriptElement.supports('speculationrules')
    ) {
      return undefined;
    }

    const isUrlsConfig = !!urls?.[0];
    const scriptEl = document.createElement('script');
    scriptEl.type = 'speculationrules';
    scriptEl.id = `${id}`;
    scriptEl.text = JSON.stringify({
      [action]: compact([
        isUrlsConfig && {
          urls,
          eagerness,
        },
        !isUrlsConfig && {
          where: {
            and: compact([
              paths && { href_matches: paths },
              selector && { selector_matches: selector },
              notSelector && { not: { selector_matches: notSelector } },
              { not: { selector_matches: '[data-no-speculation]' } },
            ]),
          },
          eagerness,
        },
      ]),
    });

    document.body.appendChild(scriptEl);

    return () => {
      document.body.removeChild(scriptEl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paths?.join(','), urls?.join(','), selector, canSpeculate]);

  useEffect(() => {
    // to reset speculation on zip change
    if (!canSpeculate && !observeRef?.current) {
      setCanSpeculate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSpeculate]);

  return null;
}

SpeculationRuleUI.propTypes = {
  observeRef: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
  observeTimeout: PropTypes.number,
  paths: PropTypes.arrayOf(PropTypes.string),
  urls: PropTypes.arrayOf(PropTypes.string),
  selector: PropTypes.string,
  notSelector: PropTypes.string,
  action: PropTypes.oneOf(['prefetch', 'prerender']),
  eagerness: PropTypes.oneOf(['immediate', 'eager', 'moderate', 'conservative']),
  disableSpeculation: PropTypes.bool,
  zipCode: PropTypes.string,
};

SpeculationRuleUI.defaultProps = {
  observeRef: null,
  observeTimeout: DEFAULT_TIMEOUT,
  paths: null,
  urls: null,
  selector: null,
  action: 'prerender',
  eagerness: 'eager',
  disableSpeculation: false,
  zipCode: null,
};

const mapStateToProps = state => ({
  disableSpeculation: !!get(state, 'featureFlags["disable-speculation"]'),
});

export const SpeculationRule = connect(mapStateToProps)(
  connectToModel(SpeculationRuleUI, {
    zipCode: bindToPath('location.zipCode', VisitorModel),
  })
);
